import React, { useEffect, useState, useCallback } from "react";

import { useWallet, useConnection } from "@solana/wallet-adapter-react";
import { useLocation, useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import "./raffle-details.css";
import bacl_arrow_img from "../images/Arrow 3 (Stroke).png";
import nft_img_1 from "../images/956.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTimer } from "react-timer-hook";
import moment from "moment";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";

import {
  createDepositTransaction,
  getDataByID,
  getDataByOwner,
  getPollData,
} from "../util/deposit";

function MyTimer({ expiryTimestamp }) {
  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({
    expiryTimestamp,
    onExpire: () => window.location.reload(),
  });

  return (
    <div
      style={{ textAlign: "center" }}
      className="poll-time-detail badge  fs-8 fw-bold"
    >
      <div id="countdown" className="countdownHolder">
        <span className="countDays">
          <span className="position">
            <span className="digit static">{days}d</span>
          </span>
        </span>
        <span className="countDiv countDiv0"></span>
        <span className="countHours">
          <span className="position">
            {" "}
            <span className="digit static">{hours}h</span>{" "}
          </span>
        </span>
        <span className="countDiv countDiv1"></span>
        <span className="countMinutes">
          <span className="position">
            {" "}
            <span className="digit static">{minutes}m</span>{" "}
          </span>
        </span>
        <span className="countDiv countDiv1"></span>
        <span className="countSeconds">
          <span className="position">
            {" "}
            <span className="digit static">{seconds}s</span>{" "}
          </span>
        </span>
      </div>
    </div>
  );
}

export const RaffleDetail = () => {
  const { connection } = useConnection();
  const { publicKey, sendTransaction } = useWallet();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [showInlineLoader, setShowInlineLoader] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const [poll, setPoll] = useState({});
  const [pollPrediction, setPollPrediction] = useState(-1);
  const [raffle1, setRaffle1] = useState({});
  const [depositAmount, setDepositAmount] = useState(0);
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
  const [FloorPrice, setFloorPrice] = useState("");
  const [TotalPoll, setTotalPoll] = useState(0);
  const [roi, setROI] = useState(0);
  const [totalLongVol, setTotalLongVol] = useState(0);
  const [totalShortVol, setTotalShortVol] = useState(0);
  useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        const ismobile = window.innerWidth < 1200;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      },
      false
    );
  }, [isMobile]);

  useEffect(() => {
    let url = window.location.href.split("/");
    if (url.length > 2) {
      if (url[3] === "poll") {
        let poll_id = url[4];
        get_poll_details(poll_id);
      }
    }
    // get_dao_by_id();
  }, [location]);

  const get_poll_details = async (poll_id) => {
    setIsLoading(true);
    let r = await get_poll(poll_id);
    const time = new Date(Number(r.pollEndTime) * 1000);
    r["timer"] = time.getTime();
    r["poll_ended"] = await calculate_time(r.pollEndTime);
    if (!r["poll_ended"]) {
      let participants = await getDataByID(connection, r["poll_id"]);
      console.log(participants);
      r["polls"]["id"] = participants;
    }
    if (r["poll_ended"]) {
      let time = new Date(Number(r.resultTime) * 1000);
      r["result_timer"] = time.getTime();
      r["result_ended"] = await calculate_time(r.resultTime);
      r["settlement_ended"] = await calculate_time(r.settlementTime);
    }
    // let now = moment.utc();
    // var day = moment.unix(Number(r.pollEndTime));
    // var daysDiff = day.diff(now, "days");
    // var hoursDiff = day.diff(now, "hours");
    // var minutesDiff = day.diff(now, "minutes");
    // var secondsDiff = day.diff(now, "seconds");

    // if (daysDiff > 0) {
    //   r["poll_ended"] = false;
    //   let participants = await getDataByID(connection, poll_id);
    //   console.log(participants);
    //   r["polls"]["id"] = participants;
    // } else {
    //   if (hoursDiff > 0 || minutesDiff > 0 || secondsDiff > 0) {
    //     r["poll_ended"] = false;
    //     console.log(poll_id);
    //     let participants = await getDataByID(connection, r["poll_id"]);
    //     console.log(participants);
    //     r["polls"]["id"] = participants;
    //   } else {
    //     r["poll_ended"] = true;
    //   }
    // }

    await setPoll(r);
    // console.log(r);
    setIsLoading(false);
    let c_r = await get_floor_price(r["collectionUrl"]);
    setFloorPrice(c_r);
    let p_data = await getPollData(connection, r["program_unquie_word"]);
    console.log(p_data);
    if (r["poll_ended"] || p_data === undefined) {
      setTotalPoll((Number(r['long_amount'])+Number(r['short_amount'])) / 1000000000);
    }else{
      setTotalPoll(p_data["collection"] / 1000000000);
    }

    calculate_long_and_short_vote(r["polls"]["id"]);
  };

  const calculate_long_and_short_vote = async (polls) => {
    let shot_vol = 0;
    let long_vol = 0;
    polls.forEach((p) => {
      shot_vol = shot_vol + p["short_amount"];
      long_vol = long_vol + p["long_amount"];
    });
    setTotalLongVol(long_vol);
    setTotalShortVol(shot_vol);
  };
  const calculate_time = async (time) => {
    let now = moment.utc();
    var day = moment.unix(Number(time));
    var daysDiff = day.diff(now, "days");
    var hoursDiff = day.diff(now, "hours");
    var minutesDiff = day.diff(now, "minutes");
    var secondsDiff = day.diff(now, "seconds");
    let is_ended;
    if (daysDiff > 0) {
      is_ended = false;
    } else {
      if (hoursDiff > 0 || minutesDiff > 0 || secondsDiff > 0) {
        is_ended = false;
      } else {
        is_ended = true;
      }
    }
    return is_ended;
  };
  const get_floor_price = async (c_name) => {
    try {
      const response = await fetch(
        "https://api.raffle-x.live/api/v1/getFloorPrice/" + c_name,
        { mode: "cors" }
      );
      const data = await response.text();
      return data;
    } catch (e) {
      console.log(e);
    }
  };

  const get_poll = async (id) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "getPoll/" + id,
        { mode: "cors" }
      );
      const data = await response.json();
      return data;
    } catch (e) {
      console.log(e);
    }

    // let p_data = await getPollData(connection, id)
    let participants = await getDataByID(connection, id);
    console.log(participants);
  };

  const back_to_raffle_list = async () => {
    navigate("/");
  };

  const sendAndConfirmTransaction = useCallback(
    async (transaction) => {
      try {
        let { blockhash } = await connection.getRecentBlockhash();
        transaction.feePayer = publicKey!;
        transaction.recentBlockhash = blockhash;

        let signature = await sendTransaction(transaction, connection, {skipPreflight:false});

        // await connection.confirmTransaction(signature, "confirmed");
        await toast.promise(
          connection.confirmTransaction(signature, "finalized"),
          {
            pending: "Initiating the transaction",
            success: "Your funds have been deposited successfully",
            error: "Error in transaction. Please try again later",
          }
        );

        console.log(signature);

        // if (onChange) onChange();
        setShowInlineLoader(false);
        return signature;
      } catch (error: any) {
        setShowInlineLoader(false);
        toast.warn(error.message, {
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
      }
    },
    [connection, publicKey, sendTransaction]
  );

  const PollDeposit = useCallback(
    async (d_amt, vote_type, programWord, poll_id) => {
      console.log(d_amt, vote_type, programWord, poll_id);

      if (vote_type === -1) {
        toast.warn("Please select your Prediction", {
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "light",
        });

        return;
      }

      if (d_amt <= 0) {
        console.log("error");

        toast.warn("Please enter the vaild amount", {
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "light",
        });

        return;
      }

      if (!publicKey) return;
      console.log("poll deposit");
      setShowInlineLoader(true);
      d_amt = Number(d_amt * Math.pow(10, 9));
      let sig = await sendAndConfirmTransaction(
        await createDepositTransaction(
          connection,
          publicKey,
          programWord,
          d_amt,
          vote_type,
          0
        )
      );
      if (sig) {
        let access_res = await fetch(
          process.env.REACT_APP_API_URL + "getPollDeposit",
          {
            method: "POST",
            mode: "cors",
            headers: new Headers({ "content-type": "application/json" }),
            body: JSON.stringify({
              id: poll_id,
            }),
          }
        );
        if (access_res.status === 200) {
          await toast.promise(refreshData(poll_id), {
            pending: "Refreshing poll",
            success: "Refreshing poll",
            error: "",
          });
        }
      }
    },
    [connection, publicKey, sendAndConfirmTransaction]
  );

  const ROICalculation = async (d, prediciton) => {
    d = d * Math.pow(10, 9);
    let opp_poll;
    let opp_poll_1;
    console.log(opp_poll / Math.pow(10, 9));
    if (prediciton === 1) {
      opp_poll = totalLongVol;
      opp_poll_1 = totalShortVol;
    }
    if (prediciton === 0) {
      opp_poll = totalShortVol;
      opp_poll_1 = totalLongVol;
    }
    console.log(opp_poll);
    let pool_share = (d / (opp_poll + d)) * 100;
    console.log("pool share:" + pool_share);
    console.log(opp_poll_1 / Math.pow(10, 9));
    // let roi =
    //   (pool_share * (opp_poll_1 / Math.pow(10, 9))) /
    //   (d / Math.pow(10, 9)) /
    //   100;
    let roi =
      (pool_share * (opp_poll_1 / Math.pow(10, 9))) / (d / Math.pow(10, 9));
    console.log(roi);
    setROI(roi);
  };

  useEffect(() => {
    const intervalCall = setInterval(() => {
      let url = window.location.href.split("/");
      if (url.length > 2) {
        if (url[3] === "poll") {
          let poll_id = url[4];
          loadToaster(poll_id);
        }
      }
    }, 100000);
    return () => {
      // clean up
      clearInterval(intervalCall);
    };
  }, []);

  const loadToaster = async (poll_id) => {
    await toast.promise(refreshData(poll_id), {
      pending: "Refreshing poll",
      success: "Refreshing poll",
      error: "",
    });
  };
  const refreshData = async (poll_id) => {
    let r = await get_poll(poll_id);
    const time = new Date(Number(r.pollEndTime) * 1000);
    r["timer"] = time.getTime();
    r["poll_ended"] = await calculate_time(r.pollEndTime);
    if (!r["poll_ended"]) {
      let participants = await getDataByID(connection, r["poll_id"]);
      console.log(participants);
      r["polls"]["id"] = participants;
    }
    if (r["poll_ended"]) {
      let time = new Date(Number(r.resultTime) * 1000);
      r["result_timer"] = time.getTime();
      r["result_ended"] = await calculate_time(r.resultTime);
      r["settlement_ended"] = await calculate_time(r.settlementTime);
    }

    await setPoll(r);
    // console.log(r);

    let c_r = await get_floor_price(r["collectionUrl"]);
    setFloorPrice(c_r);
    let p_data = await getPollData(connection, r["program_unquie_word"]);
    console.log(p_data);
    if (r["poll_ended"] || p_data === undefined) {
      setTotalPoll((Number(r['long_amount'])+Number(r['short_amount'])) / 1000000000);
    }else{
      setTotalPoll(p_data["collection"] / 1000000000);
    }
    calculate_long_and_short_vote(r["polls"]["id"]);
  };

  return (
    <>
      <ToastContainer pauseOnFocusLoss={false} />

      <div className="desk-top-view">
        {/* {isLoading && <PreLoader />} */}
        {isLoading && (
          <div className="loading">
            <span className="loading-dots">•</span>
            <p className="loading-text-1">
              Loading from onchain, N/W Congestion might affect the page loading
            </p>
          </div>
        )}
        {!isLoading && (
          <>
            <div className="search-container-raffle-detail pb-5 card rounded-0 bgi-no-repeat bgi-position-x-end bgi-size-cover mb-9">
              <div className="container-fluid poll-detail-page">
                <div className="row gy-0 gx-10">
                  <div className="col-12">
                    <div
                      className="d-flex pointer"
                      onClick={() => back_to_raffle_list()}
                    >
                      <div className="py-2">
                        <div className="back-arrow">
                          <i className="bi bi-arrow-left-circle"></i>
                        </div>
                      </div>

                      <div className="min-w-100px w-100 py-2 px-4 mb-3">
                        <span className="back-to">Back to collection</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="question-container">
                  <div className="row gy-0 gx-10">
                    <div  className={`${isMobile ? "col-12 " : "col-12 d-flex"}`}>
                      <div className={`${isMobile ? "text-center" : ""}`}>
                        <img
                          alt=" "
                          src={poll["pollImage"]}
                          className="poll_collection_image"
                        />
                      </div>
                      <div className="poll-detail-container">
                        <div className="poll-name mb-1">
                          {poll["pollName"]} ?
                        </div>
                        <div className="row g-5 mt-3">
                          {/* {poll["poll_ended"] && (
                            <div className="col-xl-3">
                              <div className="d-flex align-items-center poll-head-details">
                                <div className="d-flex flex-column flex-grow-1">
                                  <span className="poll-head-details-label">
                                    Opening F.P
                                  </span>
                                  <span className="poll-head-details-text">
                                    {poll["opening_floor_price"]} ◎
                                  </span>
                                </div>
                              </div>
                            </div>
                          )} */}
                          <div className="col-xl-3 col-sm-6 col-md-6">
                            <div className="d-flex align-items-center poll-head-details">
                              <div className="d-flex flex-column flex-grow-1">
                                <span className="poll-head-details-label">
                                  Total Pool
                                </span>
                                <span className="poll-head-details-text">
                                  {TotalPoll} ◎
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="col-xl-3 col-sm-6 col-md-6">
                            <div className="d-flex align-items-center poll-head-details">
                              <div className="d-flex flex-column flex-grow-1">
                                <span className="poll-head-details-label">
                                  Current F.P
                                </span>
                                <span className="poll-head-details-text">
                                  {FloorPrice !== "" && FloorPrice !== null
                                    ? FloorPrice + " ◎"
                                    : ""}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3">
                            <div className="d-flex align-items-center poll-head-details">
                              <div className="d-flex flex-column flex-grow-1">
                                <span className="poll-head-details-label">
                                  Opening F.P
                                </span>
                                {!poll["poll_ended"] && (
                                  <span className="poll-head-details-text">
                                    {FloorPrice !== "" && FloorPrice !== null
                                      ? FloorPrice + " ◎"
                                      : ""}
                                  </span>
                                )}
                                {poll["poll_ended"] && (
                                  <span className="poll-head-details-text">
                                    {poll["opening_floor_price"]} ◎
                                  </span>
                                )}
                                {!poll["poll_ended"] && (
                                  <details>
                                    <summary>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        className="feather feather-help-circle"
                                      >
                                        <circle cx="12" cy="12" r="10" />
                                        <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" />
                                        <line
                                          x1="12"
                                          y1="17"
                                          x2="12.01"
                                          y2="17"
                                        />
                                      </svg>
                                    </summary>
                                    <small className="form-group-help">
                                      Opening floor price for this poll will be
                                      freezed at{" "}
                                      {moment
                                        .unix(poll["pollEndTime"])
                                        .format("DD MMM YY h:mm A")}
                                    </small>
                                  </details>
                                )}
                                {poll["poll_ended"] && (
                                  <span className="forze-text">Froze</span>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3 col-sm-6 col-md-6">
                            <div className="d-flex align-items-center poll-head-details">
                              <div className="d-flex flex-column flex-grow-1">
                                <span className="poll-head-details-label">
                                  {poll["poll_ended"]
                                    ? "Poll Ended On"
                                    : "Ends In"}
                                </span>
                                <span className="poll-head-details-text">
                                  {poll["poll_ended"] ? (
                                    moment
                                      .unix(poll["pollEndTime"])
                                      .format("DD MMM YY h:mm A")
                                  ) : (
                                    <MyTimer expiryTimestamp={poll["timer"]} />
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        {poll["poll_ended"] && (
                          <div className="row g-5 mt-3 g-xl-8">
                            <div className="col-xl-3">
                              <div className="d-flex align-items-center poll-head-details">
                                <div className="d-flex flex-column flex-grow-1">
                                  <span className="poll-head-details-label">
                                    Closing F.P
                                  </span>
                                  {!poll["result_ended"] && (
                                    <span className="poll-head-details-text">
                                      {FloorPrice !== "" && FloorPrice !== null
                                        ? FloorPrice + " ◎"
                                        : ""}
                                    </span>
                                  )}
                                  {poll["result_ended"] && (
                                    <span className="poll-head-details-text">
                                      {poll["closing_floor_price"]} ◎
                                    </span>
                                  )}
                                  {!poll["result_ended"] && (
                                    <details>
                                      <summary>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          className="feather feather-help-circle"
                                        >
                                          <circle cx="12" cy="12" r="10" />
                                          <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" />
                                          <line
                                            x1="12"
                                            y1="17"
                                            x2="12.01"
                                            y2="17"
                                          />
                                        </svg>
                                      </summary>
                                      <small className="form-group-help">
                                        Closing floor price for this poll will
                                        be freezed at{" "}
                                        {moment
                                          .unix(poll["resultTime"])
                                          .format("DD MMM YY h:mm A")}
                                      </small>
                                    </details>
                                  )}
                                  {poll["result_ended"] && (
                                    <span className="forze-text">Froze</span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="col-xl-3">
                              <div className="d-flex align-items-center poll-head-details">
                                <div className="d-flex flex-column flex-grow-1">
                                  <span className="poll-head-details-label">
                                    Result Time
                                  </span>
                                  <span className="poll-head-details-text">
                                    {poll["result_ended"] ? (
                                      moment
                                        .unix(poll["resultTime"])
                                        .format("DD MMM YY h:mm A")
                                    ) : (
                                      <MyTimer
                                        expiryTimestamp={poll["result_timer"]}
                                      />
                                    )}
                                  </span>
                                </div>
                              </div>
                            </div>
                            {/* <div className="col-xl-3">
                            <div className="d-flex align-items-center poll-head-details">
                              <div className="d-flex flex-column flex-grow-1">
                                <span className="poll-head-details-label">
                                  Settlement Time

                                </span>
                                <span className="poll-head-details-text">
                                {poll["settlement_ended"] ? (
                                    moment
                                      .unix(poll["pollEndTime"])
                                      .format("DD MMM YY h:mm A")
                                  ) : (
                                    <MyTimer expiryTimestamp={poll["timer"]} />
                                  )}

                                </span>
                              </div>
                            </div>
                          </div> */}
                            {poll["result_ended"] && poll["result"] !== "" && (
                              <>
                                <div className="col-xl-3">
                                  <div className="d-flex align-items-center poll-head-details">
                                    <div className="d-flex flex-column flex-grow-1">
                                      <span className="poll-head-details-label">
                                        Winning Poll
                                      </span>
                                      <span className="poll-head-details-text">
                                        {poll["result"]}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-xl-3">
                                  <div className="d-flex align-items-center poll-head-details">
                                    <div className="d-flex flex-column flex-grow-1">
                                      <span className="poll-head-details-label">
                                        Settlement Status
                                      </span>
                                      <span className="poll-head-details-text">
                                        {poll['settlementStatus'] !== 'failed' && poll["result"] !== "" ? "Settled" : "In Progress"}

                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        )}
                        {!poll["poll_ended"] && (
                          <>
                            <div className="row gy-0 gx-10 text-center mt-9">
                              <div className="col-4">
                                <div
                                  className={`${
                                    pollPrediction === 1
                                      ? "predict-up-btn-container active"
                                      : "predict-up-btn-container"
                                  }`}
                                  onClick={() => setPollPrediction(1)}
                                >
                                  Going Up{" "}
                                  <i className="up-icon bi bi-arrow-up-right"></i>
                                  {/* <i className=" bi bi-arrow-up-right-circle-fill"></i> */}
                                </div>
                              </div>
                              <div className="col-4">
                                <div
                                  className={`${
                                    pollPrediction === 0
                                      ? "predict-down-btn-container active"
                                      : "predict-down-btn-container"
                                  }`}
                                  onClick={() => setPollPrediction(0)}
                                >
                                  Going Down{" "}
                                  <i className="down-icon bi bi-arrow-down-right"></i>
                                  {/* <i className="down-icon bi bi-arrow-down-right-circle-fill"></i> */}
                                </div>
                              </div>
                            </div>
                            <div className="row gy-0 gx-10 mt-9">
                              <div className="col-9 mb-5">
                                <label className="form-label mb-3  poll-form-label">
                                  Deposit SOL
                                </label>

                                <input
                                  type="number"
                                  min="1"
                                  className="form-control form-control-lg form-control-solid"
                                  onChange={(e) => {
                                    setDepositAmount(Number(e.target.value));
                                    ROICalculation(
                                      e.target.value,
                                      pollPrediction
                                    );
                                  }}
                                  value={depositAmount}
                                  placeholder=""
                                />
                              </div>
                              {depositAmount > 0 && pollPrediction !== -1 && (
                                <div className="col-3 mb-5 mt-4">
                                  <div className="d-flex align-items-center poll-head-details">
                                    <div className="d-flex flex-column flex-grow-1">
                                      <span className="poll-head-details-label">
                                        Potential ROI
                                      </span>
                                      <span className="poll-head-details-text">
                                        {roi.toFixed(2)} %
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="row gy-0 gx-10 mt-9">
                              <div className="col-4 mb-5">
                                {!publicKey && (
                                  <WalletMultiButton className="btn connect-wallet-btn connect-wallet-p-d-page fw-bolder">
                                    Connect wallet to predict
                                  </WalletMultiButton>
                                )}
                                {publicKey && (
                                  <button
                                    className="predict-now-btn-detail btn"
                                    onClick={() => {
                                      PollDeposit(
                                        depositAmount,
                                        pollPrediction,
                                        poll["program_unquie_word"],
                                        poll["poll_id"]
                                      );
                                    }}
                                    disabled={showInlineLoader}
                                  >
                                    {showInlineLoader ? (
                                      <>
                                        <Spinner
                                          as="span"
                                          size="sm"
                                          role="status"
                                          aria-hidden="true"
                                          animation="border"
                                        />{" "}
                                        Processing Your Prediction
                                      </>
                                    ) : (
                                      "Predict Now"
                                    )}
                                  </button>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="participant-container">
                  <div className="row gy-0 gx-10">
                    <div className="col-12 mb-2 mt-5">
                      <div className="predict-label">Participants</div>
                    </div>
                    <div className="col-xl-12 mb-2">
                      <table className="rwd-table">
                        <tbody>
                          <tr>
                            <th>Wallet</th>
                            <th>Long Vote</th>
                            <th>Short Vote</th>
                          </tr>
                          {"polls" in poll &&
                            "id" in poll["polls"] &&
                            poll["polls"]["id"].length === 0 && (
                              <tr>
                                <td className="text-center no-participants-poll" colSpan={3}>
                                  No Participants
                                </td>
                              </tr>
                            )}
                          {"polls" in poll &&
                            poll["polls"]["id"].length > 0 &&
                            poll["polls"]["id"].map((participant, index) => (
                              <>
                                {participant !== null && (
                                  <tr>
                                    <td className="wallet-id" data-th="Wallet">
                                      {participant.wallet_id}
                                    </td>
                                    <td data-th="Long Vote">
                                      {participant.long_vote}{" "}
                                      <span>
                                        {participant.long_vote > 0
                                          ? "(" +
                                            participant.long_amount /
                                              1000000000 +
                                            " SOL)"
                                          : ""}
                                      </span>
                                    </td>
                                    <td data-th="Short Vote">
                                      {participant.short_vote}{" "}
                                      <span>
                                        {participant.short_vote > 0
                                          ? "(" +
                                            participant.short_amount /
                                              1000000000 +
                                            " SOL)"
                                          : ""}
                                      </span>
                                    </td>
                                  </tr>
                                )}
                              </>
                            ))}
                          {/* <tr>
                            <td data-th="Wallet">
                              94Q2QhtrLUxLItxrgSJeXMi8jdkVTQK4EZphfT9LYsmE
                            </td>
                            <td data-th="Type">Long</td>

                            <td data-th="Amount">3.12</td>
                          </tr>
                          <tr>
                            <td data-th="Wallet">
                              94Q2QhtrLUxLItxrgSJeXMi8jdkVTQK4EZphfT9LYsmE
                            </td>
                            <td data-th="Type">Long</td>

                            <td data-th="Amount">3.12</td>
                          </tr> */}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {/* <div className="poll-row">
                  <div className="poll-left-col">
                    <div className="row gy-0 gx-10">
                      <div className="col-9">
                        <div className="poll-name mb-0">
                          Will Vault-x-da value go up or down at Feb 23 2023
                          6.30pm?
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="poll-name mb-0">Vault-X #2657</div>
                      </div>
                    </div>
                  </div>
                  <div className="poll-right-col">
                    <div className="heading-3 mx-5 mt-5">Predict</div>
                    <div className="vote-card mx-5 my-5">
                      <div className="vote-card-left">Going Up</div>
                      <div className="vote-card-right">
                        $14 at stake 2.05x Potential ROI
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
