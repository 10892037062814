import React, { useEffect, useState, useReducer } from "react";

import { useWallet, useConnection } from "@solana/wallet-adapter-react";
import { useLocation, useNavigate, Navigate, Link } from "react-router-dom";
import {
  RaffleData,
  createBuyTicketsTransaction,
  createRevealWinnerTransaction,
  createRevealWLWinnersTransaction,
  createClaimPrizeTransaction,
  createCancelRaffleTransaction,
} from "../util/raffle";
import { PublicKey } from "@solana/web3.js";
import moment from "moment";
import "./my-account.css";
import logo_white from "../images/logo-white.png";
import collection_img from "../images/Group 193.png";
import nft_img_1 from "../images/956.png";
import my_account_img from "../images/Rectangle 31.png";
import flame_img from "../images/Flame.png";
import { useTimer } from "react-timer-hook";
import reach_us_img from "../images/contact-us.png";
import Swal from "sweetalert2";
import { PreLoader } from "../components/pre-loader";
import no_raffle_img from "../images/empty-box.png";
import {
  Metadata,
  MetadataDataData,
} from "@metaplex-foundation/mpl-token-metadata";
function MyTimer({ expiryTimestamp }) {
  expiryTimestamp = new Date(Number(expiryTimestamp) * 1000);
  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({
    expiryTimestamp,
    onExpire: () => window.location.reload(),
  });

  return (
    <div>
      <div id="countdown" className="countdownHolder">
        <span className="countDays">
          <span className="position">
            <span className="digit static">{days}d</span>
          </span>
        </span>
        <span className="countDiv countDiv0"></span>
        <span className="countHours">
          <span className="position">
            {" "}
            <span className="digit static">{hours}h</span>{" "}
          </span>
        </span>
        <span className="countDiv countDiv1"></span>
        <span className="countMinutes">
          <span className="position">
            {" "}
            <span className="digit static">{minutes}m</span>{" "}
          </span>
        </span>
        <span className="countDiv countDiv1"></span>
        <span className="countSeconds">
          <span className="position">
            {" "}
            <span className="digit static">{seconds}s</span>{" "}
          </span>
        </span>
      </div>
    </div>
  );
}

export const Myaccount = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { connection } = useConnection();
  const { publicKey, sendTransaction } = useWallet();
  const location = useLocation();
  const [my_account, setMy_account] = useState();
  const [my_polls, setMy_polls] = useState([]);

  const [sticky, setSticky] = useState("");
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const [activePoll, setActivePoll] = useState([]);
  const [pastPoll, setPastPoll] = useState([]);

  const [showPurchasedTable, setShowPurchasedTable] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
  const navigate = useNavigate();

  const [isPollTimeEnd, setisPollTimeEnd] = useState(false);

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        const ismobile = window.innerWidth < 960;
        if (ismobile !== isMobile) {
          setIsMobile(ismobile);
          setShowPurchasedTable(isMobile);
        }
      },
      false
    );
  }, [isMobile]);

  const get_my_details = async () => {
    if (!publicKey) return;
    try {
      setIsLoading(true);
      let access_res = await fetch(
        process.env.REACT_APP_API_URL + "getAccountData",
        {
          method: "POST",
          mode: "cors",
          headers: new Headers({ "content-type": "application/json" }),
          body: JSON.stringify({
            wallet_id: publicKey.toString(),
          }),
        }
      );
      if (access_res.status === 200) {
        const data = await access_res.json();
        await setActivePoll(data["active_polls"]);
        await setPastPoll(data["past_polls"]);
        setMy_account(data);
        setMy_polls(data["polls"]["id"]);
        console.log(data);
      }

      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    let url = window.location.href.split("/");
    if (url.length > 2) {
      if (url[3] === "my-account" && url[4] === "twitter") {
        console.log(url);
      }
    }
    // get_dao_by_id();
  }, [location]);

  useEffect(() => {
    get_my_details();

    // get_dao_by_id();
  }, [publicKey]);

  const tab_toggler = async () => {
    setShowPurchasedTable(!showPurchasedTable);
  };

  let call_raffle_details = (param) => (e) => {
    navigate("/raffle/" + param.id);
  };

  // on render, set listener
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);

  const isSticky = () => {
    let featured_height = document.querySelector(".user-card");

    let scroll;
    if (featured_height !== null) {
      scroll = document.querySelector(".user-card").clientHeight;
    } else {
      scroll = 100;
    }

    /* Method that will fix header after a specific scrollable */
    const scrollTop = window.scrollY;
    const stickyClass = scrollTop >= scroll ? "is-sticky" : "";
    setSticky(stickyClass);
  };

  const call_poll_details = (e, id) => {
    e.preventDefault();
    console.log("Row Id", id);
    navigate("/poll/" + id);
  };

  useEffect(() => {
    calculate_floor_price();
  }, [activePoll]);

  const calculate_floor_price = async () => {
    let newArr = activePoll;
    try {
      for (const p of newArr) {
        let f_price;
        try {
          const response = await fetch(
            "https://api.raffle-x.live/api/v1/getFloorPrice/" +
              p["collectionUrl"],
            { mode: "cors" }
          );
          if (response.status === 200) {
            let data = await response.json();
            f_price = data.toFixed(2);
          } else {
            f_price = "";
          }
        } catch (e) {
          console.log(e);
          f_price = "";
        }
        console.log("ff");
        let index = newArr.indexOf(p);
        console.log(f_price);
        if (newArr[index]["opening_floor_price"] !== undefined) {
          newArr[index]["opening_floor_price"] = f_price + " ◎";
        }
      }
      await setActivePoll(newArr);
      forceUpdate();
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <>
      {publicKey ? (
        <>
          <div className="desk-top-view">
            {isLoading && <PreLoader />}
            {/* {!isLoading && ( */}
            <>
              <div className="my-account container-custom container-xxl d-lg-flex mb-7 flex-column w-100">
                <>
                  <div
                    className={`${
                      sticky === ""
                        ? "row pt-5 mt-5"
                        : "row pt-8 mt-5 is-sticky"
                    }`}
                  >
                    <div className="col-12 mb-7">
                      <div className="predict-label">My Prediction</div>
                    </div>
                    <div className="col-xl-12 mb-5">
                      <div className="d-flex">
                        <div
                          onClick={() => tab_toggler()}
                          className={`${
                            showPurchasedTable
                              ? "home-tab active py-3 px-5 mb-3"
                              : "home-tab py-3 px-5 mb-3"
                          }`}
                        >
                          Active
                        </div>
                        <div
                          onClick={() => tab_toggler()}
                          className={`${
                            !showPurchasedTable
                              ? "home-tab active py-3 px-5 mx-2 mb-3"
                              : "home-tab py-3 px-5 mx-2 mb-3"
                          }`}
                        >
                          Past
                        </div>
                      </div>
                    </div>
                  </div>
                  {showPurchasedTable && (
                    <>
                      {activePoll.length === 0 && (
                        <div className="row gy-5 g-xl-10">
                          <div className="col-xl-12 text-center">
                            <img
                              width="170"
                              src={no_raffle_img}
                              alt="no_raffle"
                            />
                            <div className="fs-2 text-white">
                              No active Polls
                            </div>
                          </div>
                        </div>
                      )}

                      {activePoll.length > 0 && (
                        <div className="row gy-5 g-xl-10">
                          <div className="col-xl-12">
                            <table className="rwd-table">
                              <tbody>
                                <tr>
                                  <th>Collection name</th>
                                  <th>Opening F.P</th>

                                  <th>Poll Ends On</th>
                                  <th>Settlement Time</th>
                                  <th>Total Pool</th>
                                  <th>My Deposit (Long + Short)</th>

                                  <th>Actions</th>
                                </tr>
                                {activePoll
                                  .slice(0)
                                  .reverse()
                                  .map((poll, index) => (
                                    <tr>
                                      <td data-th="Collection Name">
                                        <img
                                          className="poll-image"
                                          src={poll["pollImage"]}
                                        />
                                        {poll["collectionName"]}
                                      </td>
                                      <td data-th="Opening F.P">
                                        {/* {get_floor_price('y00ts')} */}
                                        {poll["opening_floor_price"]}
                                      </td>

                                      <td data-th="Poll Ends On">
                                        <MyTimer
                                          expiryTimestamp={poll["pollEndTime"]}
                                        />
                                        {/* {moment
                                        .unix(poll["pollEndTime"])
                                        .format("DD MMM YYYY h:mm A")} */}
                                      </td>
                                      <td data-th="Settlement Time">
                                        <MyTimer
                                          expiryTimestamp={
                                            poll["settlementTime"]
                                          }
                                        />
                                        {/* {moment
                                        .unix(poll["settlementTime"])
                                        .format("DD MMM YYYY h:mm A")} */}
                                      </td>
                                      <td data-th="Total Pool">
                                        {(
                                          poll["long_amount"] / 1000000000 +
                                          poll["short_amount"] / 1000000000
                                        ).toFixed(2)}{" "}
                                        ◎
                                      </td>

                                      <td data-th="My Deposit (Long + Short)">
                                        {/* {poll['my_long_vote']}{" "}({poll['my_long_amount'] > 0 ? poll['my_long_amount']/1000000000: "0"}{" ◎"})+ {" "}{" "}
                                {poll['my_short_vote']}{" "}({poll['my_short_amount'] > 0 ? poll['my_short_amount']/1000000000: "0"}{" ◎"}) */}
                                        {poll["my_long_amount"] > 0
                                          ? poll["my_long_amount"] / 1000000000
                                          : "0"}
                                        {" ◎ "}+{" "}
                                        {poll["my_short_amount"] > 0
                                          ? poll["my_short_amount"] / 1000000000
                                          : "0"}
                                        {" ◎ "}
                                      </td>

                                      <td data-th="Actions">
                                        {" "}
                                        <button
                                          className="btn predict-now-btn"
                                          onClick={(e) =>
                                            call_poll_details(e, poll["id"])
                                          }
                                        >
                                          {" "}
                                          View Poll
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                  {!showPurchasedTable && (
                    <>
                      {pastPoll.length === 0 && (
                        <div className="row gy-5 g-xl-10">
                          <div className="col-xl-12 text-center">
                            <img
                              width="170"
                              src={no_raffle_img}
                              alt="no_raffle"
                            />
                            <div className="fs-2 text-white">No past Polls</div>
                          </div>
                        </div>
                      )}

                      {pastPoll.length > 0 && (
                        <div className="row gy-5 g-xl-10 mb-9">
                          <div className="col-xl-12">
                            <table className="rwd-table">
                              <tbody>
                                <tr>
                                  <th>Collection name</th>
                                  <th>Opening F.P</th>
                                  <th>Closing F.P</th>
                                  <th>Poll Ends On</th>
                                  <th>Settlement Time</th>
                                  <th>Total Pool</th>
                                  <th>My Deposit (Long + Short)</th>
                                  <th>Result</th>
                                  <th>Actions</th>
                                </tr>
                                {pastPoll
                                  .slice(0)
                                  .reverse()
                                  .map((poll, index) => (
                                    <tr>
                                      <td data-th="Collection Name">
                                        <img
                                          className="poll-image"
                                          src={poll["pollImage"]}
                                        />
                                        {poll["collectionName"]}
                                      </td>
                                      <td data-th="Opening F.P">
                                        {poll["opening_floor_price"]}
                                      </td>
                                      <td data-th="Closing F.P">
                                        {poll["result"] != ""
                                          ? poll["opening_floor_price"]
                                          : ""}
                                      </td>
                                      <td data-th="Poll Ends On">
                                        {moment
                                          .unix(poll["pollEndTime"])
                                          .format("DD MMM YYYY h:mm A")}
                                      </td>
                                      <td data-th="Settlement Time">
                                        {moment
                                          .unix(poll["settlementTime"])
                                          .format("DD MMM YYYY h:mm A")}
                                      </td>
                                      <td data-th="Total Pool">
                                        {(
                                          poll["long_amount"] / 1000000000 +
                                          poll["short_amount"] / 1000000000
                                        ).toFixed(2)}{" "}
                                        ◎
                                      </td>

                                      <td data-th="My Deposit (Long + Short)">
                                        {/* {poll['my_long_vote']}{" "}({poll['my_long_amount'] > 0 ? poll['my_long_amount']/1000000000: "0"}{" ◎"})+ {" "}{" "}
                                    {poll['my_short_vote']}{" "}({poll['my_short_amount'] > 0 ? poll['my_short_amount']/1000000000: "0"}{" ◎"}) */}
                                        {poll["my_long_amount"] > 0
                                          ? poll["my_long_amount"] / 1000000000
                                          : "0"}
                                        {" ◎ "}+{" "}
                                        {poll["my_short_amount"] > 0
                                          ? poll["my_short_amount"] / 1000000000
                                          : "0"}
                                        {" ◎ "}
                                      </td>
                                      <td data-th="Result">{poll["result"]}</td>
                                      <td data-th="Actions">
                                        {" "}
                                        <button
                                          className="btn predict-now-btn"
                                          onClick={(e) =>
                                            call_poll_details(e, poll["id"])
                                          }
                                        >
                                          {" "}
                                          View Poll
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </>
              </div>
            </>
            {/* )} */}
          </div>
          {/* <div className="mobile-view text-white fs-2">
            <img src={logo_white} />
            <div>Please use this app in desktop for better experience</div>
          </div> */}
        </>
      ) : (
        <div
          id="kt_content_container"
          className="d-flex flex-column-fluid align-items-start "
        >
          <div
            className={`${
              isMobile
                ? "px-5"
                : "d-flex flex-column flex-lg-row flex-column-fluid stepper stepper-pills stepper-column stepper-multistep first"
            }`}
          >
            <div
              className={`${
                isMobile ? "" : "d-flex flex-column flex-lg-row-fluid py-10"
              }`}
            >
              <div
                className={`${
                  isMobile
                    ? ""
                    : "d-flex flex-center flex-column flex-column-fluid"
                }`}
              >
                <div className="row">
                  <div className="col-12">
                    <div className="create-raffle-wallet-connet">
                      <img className="reach-us-img" alt="" src={reach_us_img} />
                      <div className="reach-us-to-whitelist">
                        Please connect your wallet.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
