import React, {
  useCallback,
  useState,
  useEffect,
  useRef,
  useReducer,
} from "react";
import { useWallet, useConnection } from "@solana/wallet-adapter-react";
import { Navigate, useNavigate, Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";
import { LAMPORTS_PER_SOL, PublicKey, Keypair } from "@solana/web3.js";
import moment from "moment";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";

import {
  createDepositTransaction,
  getDataByID,
  getDataByOwner,
  getPollData,
} from "../util/deposit";
import "./create-raffle.css";

const hourMilliSeconds = 1000 * 3600;

const addDays = (d, num) => {
  let time = d.getTime();
  time += hourMilliSeconds * 24 * num;
  return new Date(time);
};

const getNearestHour = () => {
  const now = new Date().getTime();
  return new Date(now - (now % hourMilliSeconds) + hourMilliSeconds);
};

export const CreateIndividualRaffle = () => {
  const { connection } = useConnection();
  const { wallet } = useWallet();
  const { publicKey, sendTransaction } = useWallet();
  const [collectionName, setCollectionName] = useState("");
  const [allDao, setallDao] = useState([]);
  const [endTime, setendTime] = useState(addDays(getNearestHour(), 0));
  const [resultTime, setresultTime] = useState(addDays(getNearestHour(), 0));
  const [disabledHours, setDisabledHours] = useState(
    setHours(setMinutes(new Date(), 0), 0)
  );
  const programWord = "test122";

  const deposit_amount = 100_00_00_00;
  const vote = 0;

  const sendAndConfirmTransaction = useCallback(
    async (transaction) => {
      let { blockhash } = await connection.getRecentBlockhash();
      transaction.feePayer = publicKey!;
      transaction.recentBlockhash = blockhash;

      let signature = await sendTransaction(transaction, connection, {skipPreflight:false});
      await connection.confirmTransaction(signature, "confirmed");

      console.log(signature);

      // if (onChange) onChange();

      return signature;
    },
    [connection, publicKey, sendTransaction]
  );

  const PollDeposit = useCallback(async () => {
    if (!publicKey) return;
    let participants = await getDataByID(connection, "1232");
    let poll_data = await getDataByOwner(connection, publicKey);
    let p_data = await getPollData(connection, programWord);

    console.log(p_data);
    console.log(poll_data);
    console.log(participants);
    console.log(programWord, deposit_amount, vote);
    await sendAndConfirmTransaction(
      await createDepositTransaction(
        connection,
        publicKey,
        programWord,
        deposit_amount,
        vote,
        0
      )
    );
  }, [connection, publicKey, sendAndConfirmTransaction]);

  let handleSubmit = async (e) => {
    e.preventDefault();

    if (collectionName === "") {
      toast.warn("Please select the Collection", {
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    // if (endTime === "") {
    //   Swal.fire({
    //     text: "Please select the Poll Enddate",
    //     confirmButtonText: "Close",
    //   });
    //   return;
    // }
    // if (resultTime === "") {
    //   Swal.fire({
    //     text: "Please select the Result time",
    //     confirmButtonText: "Close",
    //   });
    //   return;
    // }

    let endTimeasms = Math.floor(moment(endTime).valueOf() / 1000);
    let resultTimeasms = Math.floor(moment(resultTime).valueOf() / 1000);

    await toast.promise(save_poll(collectionName, endTimeasms, resultTimeasms), {
      pending: "Creating poll",
      success: "Poll Created",
      error: "",
    });
  };

  let save_poll = async (c,e,r) => {
    let response = await fetch(
      process.env.REACT_APP_API_URL + "rapid_poll_request",
      {
        method: "POST",
        mode: "cors",
        headers: new Headers({ "content-type": "application/json" }),
        body: JSON.stringify({
          collectionName: c,
          pollEndTime: e.toString(),
          resultTime: r.toString(),
        }),
      }
    );

    const data = await response.json();
    console.log(data);
  }
  useEffect(() => {
    get_dao();
  }, []);

  let get_dao = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "get_all_dao",
        { mode: "cors" }
      );
      if (response.status === 200) {
        const data = await response.json();
        setallDao(data);
      } else {
        setallDao([]);
      }
    } catch (e) {}
  };

  return (
    <>
    <ToastContainer pauseOnFocusLoss={false} />
      <div
        id="kt_content_container"
        className="d-flex flex-column-fluid align-items-start  create-raffle-page-container"
      >
        <div className="d-flex flex-column flex-lg-row flex-column-fluid stepper stepper-pills stepper-column stepper-multistep first">
          <div className="d-flex flex-column flex-lg-row-fluid py-10">
            <div className="d-flex flex-center flex-column flex-column-fluid">
              <div className="col-xl-9 col-md-9">
                <div className="pending step-2">
                  <div>
                    <div className="row">
                      <div className="col-lg-9 col-md-9  col-sm-12">
                        <div className="pb-10 pb-lg-15">
                          <h2 className="f-size-40 d-flex align-items-center text-white">
                            Create New Poll
                          </h2>
                        </div>
                      </div>
                    </div>

                    <div className="row fv-row mb-7 fv-plugins-icon-container">
                      <div className="col-xl-6">
                        <label className="form-label mb-3  create-raffle-label">
                          Collection Name
                        </label>
                        <select
                          className="raffle-price-type-select form-select form-select-solid"
                          name="created_for"
                          value={collectionName}
                          onChange={(e) => setCollectionName(e.target.value)}
                        >
                          {allDao.map((t, k) => (
                            <option value={t["daoUrl"]}>{t["daoName"]}</option>
                          ))}
                        </select>

                      </div>
                    </div>
                    <div className="row fv-row mb-7 fv-plugins-icon-container">
                      <div className="col-xl-6">
                        <label className="form-label mb-3 create-raffle-label">
                          Poll End Date
                        </label>

                        <DatePicker
                          minDate={moment().add(0, "days").toDate()}
                          timeInputLabel="Time:"
                          showTimeInput
                          minTime={disabledHours}
                          maxTime={setHours(setMinutes(new Date(), 0), 23)}
                          className="form-control form-control-solid"
                          dateFormat="MM/dd/yyyy h:mm aa"
                          selected={endTime}
                          onChange={(date) => setendTime(date)}
                        />
                      </div>
                      <div className="col-xl-6">
                        <label className="form-label mb-3 create-raffle-label">
                          Result Time
                        </label>

                        <DatePicker
                          minDate={moment().add(0, "days").toDate()}
                          timeInputLabel="Time:"
                          showTimeInput
                          minTime={disabledHours}
                          maxTime={setHours(setMinutes(new Date(), 0), 23)}
                          className="form-control form-control-solid"
                          dateFormat="MM/dd/yyyy h:mm aa"
                          selected={resultTime}
                          onChange={(date) => setresultTime(date)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-stack pt-15 float-right">
                    <div className="mr-2">
                      <div className="text-center">
                        <button
                          type="submit"
                          className="btn bt-lg create-raffle-btn"
                          onClick={handleSubmit}
                        >
                          Create Poll
                          <span className="svg-icon svg-icon-4 ms-1">
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                opacity="0.5"
                                x="18"
                                y="13"
                                width="13"
                                height="2"
                                rx="1"
                                transform="rotate(-180 18 13)"
                                fill="currentColor"
                              ></rect>
                              <path
                                d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                fill="currentColor"
                              ></path>
                            </svg>
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
