import {
  AccountMeta,
  Connection,
  MemcmpFilter,
  DataSizeFilter,
  PublicKey,
  SystemProgram,
  SYSVAR_RENT_PUBKEY,
  Transaction,
  TransactionInstruction,
} from "@solana/web3.js";
import {
  ASSOCIATED_TOKEN_PROGRAM_ID,
  TOKEN_PROGRAM_ID,
  Token,
} from "@solana/spl-token";
import {
  Metadata,
  MetadataDataData,
} from "@metaplex-foundation/mpl-token-metadata";
import * as bs58 from "bs58";
import { serialize, deserialize, deserializeUnchecked, } from 'borsh';

/* global BigInt */


export const PROGRAM_ID = new PublicKey(
  "49HumbfwPV2zHYpD7vA9Z4zjMcKB8EvwxDjFxMsZFeiT"
);
const TXN_FEE_VAULT = new PublicKey(
  "66zdaytnPgKeuTa6eK5tX3vpeetoP8UFLUDrCvUyY7QG"
);


// export const PROGRAM_ID = new PublicKey(
//   "7PoRmL81dWDuqdotpAyxNbWyHo8tgTcqg55hBce3Vcye"
// );
// const TXN_FEE_VAULT = new PublicKey(
//   "7mh5B3ezGCBtBh3YHHZj9GzVVuoNcdHNgSk2t6YG4SNm"
// );

class Assignable {
  constructor(properties) {
    Object.keys(properties).map((key) => {
      return (this[key] = properties[key]);
    });
  }
}
class Payload extends Assignable {}
function createInstructionData(instruction: string, poll_program_word:String,amount:any, vote:any, is_holder:any): Buffer {
  const PayloadSchema = new Map([
    [
      Payload,
      {
        kind: "struct",
        fields: [
          ["id", "u8"],
          ["poll_program_word", "string"],
          ["amount", "u64"],
          ["vote", "u8"],
          ["is_holder", "u8"],
        ],
      },
    ],
  ]);
  if (instruction === "DepositPoll"){
    const Data = new Payload({
      id:7,
      poll_program_word,
      amount,
      vote,
      is_holder
      });
    return Buffer.from(serialize(PayloadSchema, Data));
  }
  throw new Error(`Unrecognized instruction: ${instruction}`);
}

function parseUint64Le(data: Uint8Array, offset: number = 0): bigint {
  let number = BigInt(0);
  for (let i = 0; i < 8; i++)
    number += BigInt(data[offset + i]) << BigInt(i * 8);
  return number;
}


function transactionKey(
  pubkey: PublicKey,
  isSigner: boolean,
  isWritable: boolean = true
): AccountMeta {
  return {
    pubkey,
    isSigner,
    isWritable,
  };
}

export interface DepositData {
  poll_id: any,
  wallet_id: String;
  long_amount: any,
  short_amount: any,
  long_vote: any,
  short_vote: any,
}

export async function getDataByOwner(
  connection: Connection,
  owner: PublicKey
): Promise<DepositData[]> {
  let depositDataAccounts = await connection.getProgramAccounts(PROGRAM_ID, {
    filters: [
      createOwnerFilter(owner),
    ],
  });
  return Promise.all(
    depositDataAccounts.map(async ({ account: { data } }) => {
      let poll_id = Number(parseUint64Le(data, 0));
      let wallet_id = (new PublicKey(data.slice(8, 40))).toBase58();
      let long_amount = Number(parseUint64Le(data, 40));
      let short_amount = Number(parseUint64Le(data, 48));
      let long_vote = Number(parseUint64Le(data, 56));
      let short_vote = Number(parseUint64Le(data, 64));
      return {
        poll_id,
        wallet_id,
        long_amount,
        short_amount,
        long_vote,
        short_vote,
      };
    })
  );
}

export function createOwnerFilter(owner: PublicKey): MemcmpFilter {
  return {
    memcmp: {
      offset: 8,
      bytes: owner.toBase58(),
    },
  };
}
export async function getDataByID(
  connection: Connection,
  id: any
): Promise<DepositData[]> {
  let depositDataAccounts = await connection.getProgramAccounts(PROGRAM_ID, {
    filters: [
      createIDFilter(id),
      {dataSize: 72}
    ],
  });
  return Promise.all(
    depositDataAccounts.map(async ({ account: { data } }) => {
      let poll_id = Number(parseUint64Le(data, 0));
      if(poll_id!=id)return null;
      let wallet_id = (new PublicKey(data.slice(8, 40))).toBase58();
      let long_amount = Number(parseUint64Le(data, 40));
      let short_amount = Number(parseUint64Le(data, 48));
      let long_vote = Number(parseUint64Le(data, 56));
      let short_vote = Number(parseUint64Le(data, 64));
      return {
        poll_id,
        wallet_id,
        long_amount,
        short_amount,
        long_vote,
        short_vote,
      };
    })
  );
}
export function createIDFilter(id: any): MemcmpFilter {
  return {
    memcmp: {
      offset: 0,
      bytes: bs58.encode(Uint8Array.from([id])),
    },
  };
}

export async function getPollData(
  connection: Connection,
  poll_word: String,
){
  let [poll_address] = await PublicKey.findProgramAddress(
    [Buffer.from(poll_word)],
    PROGRAM_ID
  );
  let pollAccountInfo = await connection.getAccountInfo(poll_address);
  if (!pollAccountInfo) throw new Error(`${poll_address} not initialized`);
  let { data } = pollAccountInfo;
  let poll_id = Number(parseUint64Le(data, 0));
  let end_timestamp = Number(parseUint64Le(data, 8));
  let total_entries = Number(parseUint64Le(data, 16));
  let timestamp = Number(parseUint64Le(data, 24));
  let long_vote = Number(parseUint64Le(data, 32));
  let short_vote = Number(parseUint64Le(data, 40));
  let collection = Number(parseUint64Le(data, 48));
  console.log({
    poll_id, end_timestamp, total_entries, timestamp, long_vote, short_vote, collection
  });
}

export async function createDepositTransaction(
  connection: Connection,
  payer: PublicKey,
  program_word: String,
  amount: any,
  vote: any,
  is_holder:any,
): Promise<Transaction> {
  let transaction = new Transaction();
  let deposit_structs = await getDataByOwner(connection, payer);
  console.log(deposit_structs);


  getPollData(connection, program_word);
  transaction.add(
    await createDepositTransactionInstruction(payer, program_word, amount, vote, is_holder)
  );
  return transaction;
}
export async function createDepositTransactionInstruction(
  payer: PublicKey,
  program_word: String,
  amount: any,
  vote: any,
  is_holder: any
): Promise<TransactionInstruction> {
  let [poll_address] = await PublicKey.findProgramAddress(
    [Buffer.from(program_word)],
    PROGRAM_ID
  );
  let [poll_deposit_address] = await PublicKey.findProgramAddress(
    [Buffer.from(program_word), payer.toBytes()],
    PROGRAM_ID
  );

  return new TransactionInstruction({
    programId: PROGRAM_ID,
    data: createInstructionData("DepositPoll", program_word, amount, vote, is_holder),
    keys: [
      transactionKey(payer, true),

      transactionKey(poll_address, false, true),
      transactionKey(poll_deposit_address, false, true),
      transactionKey(TXN_FEE_VAULT, false, true),

      transactionKey(SystemProgram.programId, false, false),
      transactionKey(SYSVAR_RENT_PUBKEY, false, false),
    ],
  });
}
