import React, { useEffect, useState, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import { useWallet } from "@solana/wallet-adapter-react";
import { useTable } from "react-table";
import logo_white from "../images/logo-white.png";
import no_raffle_img from "../images/empty-box.png";
import "./home.css";
import { PreLoader } from "../components/pre-loader";
import Swal from "sweetalert2";
import { useTimer } from "react-timer-hook";
import moment from "moment";

function MyTimer({ expiryTimestamp }) {
  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({
    expiryTimestamp,
    onExpire: () => console.log("end"),
  });

  return (
    <div
      style={{ textAlign: "center" }}
      className="poll-time badge  fs-8 fw-bold"
    >
      <div id="countdown" className="countdownHolder">
        <span className="countDays">
          <span className="position">
            <span className="digit static">{days}d</span>
          </span>
        </span>
        <span className="countDiv countDiv0"></span>
        <span className="countHours">
          <span className="position">
            {" "}
            <span className="digit static">{hours}h</span>{" "}
          </span>
        </span>
        <span className="countDiv countDiv1"></span>
        <span className="countMinutes">
          <span className="position">
            {" "}
            <span className="digit static">{minutes}m</span>{" "}
          </span>
        </span>
        <span className="countDiv countDiv1"></span>
        <span className="countSeconds">
          <span className="position">
            {" "}
            <span className="digit static">{seconds}s</span>{" "}
          </span>
        </span>
      </div>
    </div>
  );
}

export const Home = () => {
  const { publicKey } = useWallet();
  const [isLoading, setIsLoading] = useState(false);
  const [isPreLoading, setIsPreLoading] = useState(false);
  const [homeActiveTab, setHomeActiveTab] = useState("active");
  const [activePoll, setActivePoll] = useState([]);
  const [pastPoll, setPastPoll] = useState([]);
  const [pending, setPending] = useState(true);
  const [sticky, setSticky] = useState("");
  const [showScroll, setShowScroll] = useState(false);
  const [, forceUpdate] = useReducer(x => x + 1, 0);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
  const navigate = useNavigate();

  const call_poll_details = (e, id) => {
    e.preventDefault();
    console.log("Row Id", id);
    navigate("/poll/" + id);
  };

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        const ismobile = window.innerWidth < 1200;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      },
      false
    );
  }, [isMobile]);

  const get_active_poll = async () => {
    setIsPreLoading(true);
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "get_active",
        { mode: "cors" }
      );
      const data = await response.json();
      // setPoll(data['poll_result'])
      // let poll_data = [];
      // data["poll_result"].forEach((poll, index) => {
      //   let temp = {
      //     id: index + 1,
      //     market: poll["collectionName"],
      //     floor_price: poll["opening_floor_price"],
      //     poll_ends_on: "",
      //     settlement_time: poll["settlementTime"],
      //     total_pool: "$45",
      //     potential_ROI: "1.54x",
      //     poll_id: poll["id"],
      //   };
      //   poll_data.push(temp);
      //   console.log(poll);
      // });
      // console.log(poll_data);
      let poll_data = [];
      data["poll_result"].forEach((poll, index) => {
        let temp = poll;
        const time = new Date(Number(temp.pollEndTime) * 1000);
        temp["timer"] = time.getTime();
        const time1 = new Date(Number(temp.settlementTime) * 1000);
        temp["settlement_timer"] = time1.getTime();
        console.log(temp);
        poll_data.push(temp);
      });
      await setActivePoll(poll_data);
      console.log(activePoll);
      setIsPreLoading(false);

    } catch (e) {
      console.log(e);
    }
  };

  const calculate_floor_price = async () => {

    let newArr = activePoll;
    try {
      for (const p of newArr) {
        let f_price;
        try {
          const response = await fetch(
            "https://api.raffle-x.live/api/v1/getFloorPrice/" +
              p["collectionUrl"],
            { mode: "cors" }
          );
          if (response.status === 200) {
            let data = await response.json();
            f_price = data.toFixed(2);
          } else {
            f_price = "";
          }
        } catch (e) {
          console.log(e);
          f_price = "";
        }
        console.log("ff");
        let index = newArr.indexOf(p);
        console.log(f_price);
        if (newArr[index]["opening_floor_price"] !== undefined) {
          newArr[index]["opening_floor_price"] = f_price + ' ◎';
        }


      }
      await setActivePoll(newArr);
      forceUpdate()
    } catch (e) {
      console.log(e);
    }
  };

  const get_floor_price = async (c_name) => {
    console.log(c_name);
    // try {
    //   const response = await fetch(
    //     "https://api.raffle-x.live/api/v1/getFloorPrice/" + c_name,
    //     { mode: "cors" }
    //   );
    //   const data = await response.text();
    //   console.log(data)
    //   return data;
    // } catch (e) {
    //   console.log(e);
    // }
  };

  const get_past_poll = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + "get_past", {
        mode: "cors",
      });
      const data = await response.json();
      // setPoll(data['poll_result'])
      // let poll_data = [];
      // data["poll_result"].forEach((poll, index) => {
      //   let temp = {
      //     id: index + 1,
      //     market: poll["collectionName"],
      //     floor_price: poll["opening_floor_price"],
      //     poll_ends_on: "",
      //     settlement_time: poll["settlementTime"],
      //     total_pool: "$45",
      //     potential_ROI: "1.54x",
      //     poll_id: poll["id"],
      //   };
      //   poll_data.push(temp);
      //   console.log(poll);
      // });
      // console.log(poll_data);
      let poll_data = [];
      data["poll_result"].forEach((poll, index) => {
        let temp = poll;
        const time = new Date(Number(temp.pollEndTime) * 1000);
        temp["timer"] = time.getTime();
        poll_data.push(temp);
      });
      setPastPoll(poll_data);
      // console.log(activePoll);
      return data;
    } catch (e) {
      console.log(e);
    }
  };

  let home_tab_change = async (e) => {
    setHomeActiveTab(e);

    if (e === "active") {
      // await setActiveRaffleCurrentPage(1);
      // await setIsActiveRaffleNextPage(false);
      // await setActiveRaffle([]);

      console.log("dd");
    } else if (e === "past") {
      console.log("dd");
    } else if (e === "cancelled") {
      console.log("dd");
    }
  };

  // on render, set listener
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);

  const isSticky = () => {
    let featured_height = document.querySelector(".featured-container");

    let scroll;
    if (featured_height !== null) {
      scroll = document.querySelector(".featured-container").clientHeight;
    } else {
      scroll = 100;
    }

    /* Method that will fix header after a specific scrollable */
    const scrollTop = window.scrollY;
    const stickyClass = scrollTop >= scroll ? "is-sticky" : "";
    setSticky(stickyClass);

    if (scrollTop >= 700) {
      setShowScroll(true);
    } else {
      setShowScroll(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    async function data() {
      await get_active_poll();
      await get_past_poll();

    }
    data();
  }, []);

  useEffect(() => {
      calculate_floor_price();
  }, [activePoll]);

  // const get_floor_price = async (c_name) => {
  //   try {
  //     const response = await fetch(
  //       "https://api-mainnet.magiceden.dev/v2/collections/" +
  //         c_name +
  //         "/stats/",

  //       {
  //         headers: new Headers({
  //           "User-Agent": "Mozilla/4.0",
  //         }),
  //         mode: "cors",
  //       }
  //     );
  //     const data = await response.json();
  //     return data;
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  return (
    <>
      <div className="desk-top-view">
        {isLoading && <PreLoader />}
        {/* {!isLoading && ( */}
        <div
          id="home_content_container"
          className="d-flex flex-column flex-column-fluid"
        >
          <div className="container-xxl" id="kt_content">
            <div
              className={`${
                sticky === "" ? "row pt-5 mt-5" : "row pt-8 mt-5 is-sticky"
              }`}
            >
              <div className="col-xl-8">
                <div className="d-flex">
                  <div
                    onClick={() => home_tab_change("active")}
                    className={`${
                      homeActiveTab === "active"
                        ? "home-tab active py-3 px-5 mx-2 mb-3"
                        : "home-tab py-3 px-5 mx-2 mb-3"
                    }`}
                  >
                    Active
                  </div>
                  <div
                    onClick={() => home_tab_change("past")}
                    className={`${
                      homeActiveTab === "past"
                        ? "home-tab active py-3 px-5 mx-2 mb-3"
                        : "home-tab py-3 px-5 mx-2 mb-3"
                    }`}
                  >
                    Past
                  </div>
                </div>
              </div>

              <div className="col-xl-4">
                <div className="card-toolbar mb-10">
                  {/* <div className="d-flex align-items-center float-right">
                    <span className="fs-7 fw-bold text-gray-700 pe-4 text-nowrap d-none d-xxl-block">
                      Sort By:
                    </span>

                    <select
                      className="form-select form-select-sm form-select-solid sort-by-select"
                      aria-hidden="true"
                    >
                      <option
                        value=""
                        data-select2-id="select2-data-128-3ifn"
                      ></option>
                      <option value="1" data-select2-id="select2-data-9-0hl4">
                        All
                      </option>
                      <option value="2" data-select2-id="select2-data-129-sfxc">
                        Individual
                      </option>
                      <option value="3" data-select2-id="select2-data-130-pd72">
                        DAO
                      </option>
                    </select>
                  </div> */}
                </div>
              </div>
            </div>

            {homeActiveTab === "active" && (
              <>
                {activePoll.length === 0 && !isPreLoading && (
                  <div className="row gy-5 g-xl-10">
                    <div className="col-xl-12 text-center">
                      <img width="170" src={no_raffle_img} alt="no_raffle" />
                      <div className="fs-2 text-white">No active Polls</div>
                    </div>
                  </div>
                )}

                {activePoll.length > 0 && !isPreLoading && (
                  <div className="row gy-5 g-xl-10">
                    <div className="col-xl-12">
                      <table className="rwd-table">
                        <tbody>
                          <tr>
                            <th>Collection name</th>
                            <th>Current F.P</th>
                            <th>Poll Ends On</th>
                            <th>Settlement Time</th>
                            <th>Total Pool</th>

                            <th>Actions</th>
                          </tr>
                          {activePoll.map((poll, index) => (
                            <tr>
                              <td data-th="Collection Name">
                                <img
                                  className="poll-image"
                                  src={poll["pollImage"]}
                                />
                                {poll["collectionName"]}
                              </td>
                              <td data-th="Opening F.P">{poll['opening_floor_price']}</td>
                              <td data-th="Poll Ends On">
                                <MyTimer expiryTimestamp={poll["timer"]} />
                              </td>
                              <td data-th="Settlement Time">
                              <MyTimer expiryTimestamp={poll["settlement_timer"]} />
                                {/* {moment
                                  .unix(poll["settlementTime"])
                                  .format("DD MMM YYYY h:mm A")} */}
                              </td>
                              <td data-th="Total Pool">
                                {(poll["long_amount"] / 1000000000 +
                                  poll["short_amount"] / 1000000000).toFixed(2)}{" "}
                                ◎
                              </td>

                              <td data-th="Actions">
                                {" "}
                                <button
                                  className="btn predict-now-btn"
                                  onClick={(e) =>
                                    call_poll_details(e, poll["id"])
                                  }
                                >
                                  {" "}
                                  Predict Now
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </>
            )}

            {homeActiveTab === "past" && (
              <>
                {pastPoll.length === 0 && !isPreLoading && (
                  <div className="row gy-5 g-xl-10">
                    <div className="col-xl-12 text-center">
                      <img width="170" src={no_raffle_img} alt="no_raffle" />
                      <div className="fs-2 text-white">No past Polls</div>
                    </div>
                  </div>
                )}

                {pastPoll.length > 0 && !isPreLoading && (
                  <div className="row gy-5 g-xl-10">
                    <div className="col-xl-12">
                      <table className="rwd-table">
                        <tbody>
                          <tr>
                            <th>Collection name</th>
                            <th>Opening F.P</th>
                            <th>Poll Ends On</th>
                            <th>Settlement Time</th>
                            <th>Total Pool</th>

                            <th>Actions</th>
                          </tr>
                          {pastPoll.map((poll, index) => (
                            <tr>
                              <td data-th="Collection Name">
                                <img
                                  className="poll-image"
                                  src={poll["pollImage"]}
                                />
                                {poll["collectionName"]}
                              </td>
                              <td data-th="Opening F.P">
                                {poll["opening_floor_price"]}
                              </td>
                              <td data-th="Poll Ends On">
                                {moment
                                  .unix(poll["pollEndTime"])
                                  .format("DD MMM YYYY h:mm A")}
                              </td>
                              <td data-th="Settlement Time">
                                {moment
                                  .unix(poll["settlementTime"])
                                  .format("DD MMM YYYY h:mm A")}
                              </td>
                              <td data-th="Total Pool">
                                {(poll["long_amount"] / 1000000000 +
                                  poll["short_amount"] / 1000000000).toFixed(2)}{" "}
                                ◎
                              </td>

                              <td data-th="Actions">
                                {" "}
                                <button
                                  className="btn predict-now-btn"
                                  onClick={(e) =>
                                    call_poll_details(e, poll["id"])
                                  }
                                >
                                  {" "}
                                  View Poll
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </>
            )}

            {isPreLoading && (
              <div className="spinner-container-v1">
                <div className="spinner-v1"></div>
              </div>
            )}
            <div>
              {showScroll && (
                <a className="back-to-top-button" onClick={scrollToTop}></a>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
